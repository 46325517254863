<template>
  <div>
    <div v-if="IsPurchase" class="videoRoom">
      <!-- 左视频 -->
      <div class="room-left">
        <div class="room-title">
          <div class="elp">
            {{ detail.title }}
          </div>
          <div class="time">
            <i class="iconfont icon-a-Group1339" />
            直播时间：{{ detail.startTime | format("yyyy.MM.dd HH:mm")
            }}<!-- -{{
              detail.endTime | format("HH:mm")
            }} -->
          </div>
        </div>
        <!-- 视频上方提示 -->
        <div v-if="tipsShow" class="room-Top">
          <!-- 左侧提示 -->
          <div class="wrapTopsCenTopL">
            <img src="@/assets/img/kecheng/tipsIcon.png" />
            <span>课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</span>
          </div>
          <!-- 右侧关闭 -->
          <div class="wrapTopsCenTopR" @click="tipsShow = false">
            <span>关闭</span>
            <img src="@/assets/img/kecheng/classIcon1123.png" />
          </div>
        </div>
        <!-- 视频模块： -->
        <div id="drawPanel" />
        <!-- jiangshi -->
        <div class="detail-ter m-t-20 flex-c">
          <div class="ter-list">
            <div class="terbox">
              <el-image class="ter-img" :src="liveDetail ? liveDetail.tImg : ''" fit="cover" />
            </div>
            <span class="ter">讲师：{{ liveDetail ? liveDetail.terName : "" }}</span>
          </div>
        </div>
      </div>
      <!-- 右头像/聊天 -->
      <div class="room-right">
        <!-- 讲师头像 -->
        <div id="livePlayer" />
        <cc-livebo-fang-chater :id="courseId" class="room" :playid="showId" :class-id="classId" :is-purchase="IsPurchase"
          :detail="detail" :class-product-line="classProductLine" />
      </div>
    </div>
    <div v-if="!IsPurchase" class="haveNot" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="goBack()" />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getLbClassCourseById } from "@/api/home";
import { getInfo } from "@/api/cookies";
import liveboFangChater from "@/components/Know/liveboFangChater.vue";
import { Know } from "@/api/know";
const know = new Know();
import ccLiveboFangChater from "@/components/Know/ccLiveboFangChater.vue";
export default {
  components: { ccLiveboFangChater },
  props: [
    "detail",
    "classId",
    "userid",
    "showId",
    "roomid",
    "courseId",
    "type",
    "ccConfig",
    "IsPurchase",
    "classProductLine",
  ],
  data() {
    return {
      tipsShow: true,
      // 直播 用户名称
      viewername: "",
      // 公聊
      msgList: [],
      // 输入框
      my_msg: "",
      userInfo: null,
      liveDetail: {}, // 课程详情
      liveInfo: {},
      /* 学习记录 */
      setIntervals: null,
    };
  },
  async beforeDestroy() {
    this.getLbClassCourse();
    clearInterval(this.setIntervals);
    // 停止直播
    DWLive.destroy();
  },
  created() {
    if (!this.IsPurchase) {
      return;
    }
    this.userInfo = getInfo();
    if (this.ccConfig) {
      const index = this.ccConfig.indexOf("?");
      if (index != -1) {
        var str = this.ccConfig.substr(index + 1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          this.liveInfo[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
    } else {
      this.liveInfo.userid = this.userid;
      this.liveInfo.roomid = this.roomid;
    }
    getLbClassCourseById(this.courseId).then((res) => {
      this.liveDetail = res.data.details;
    });
    this.$nextTick(function () {
      this.liveStart();
    });
    this.getMarquee()
    this.loadgetLbClassCourse();
  },

  methods: {
    stop() {
      return;
    },
    // 初始化
    liveStart() {
      if (!this.IsPurchase) {
        return;
      }
      const that = this;
      DWLive.init({
        userid: this.liveInfo.userid, // 用户 id 必须
        roomid: this.liveInfo.roomid, // 直播间id 必须
        viewername: this.userInfo.id + "-" + this.userInfo.nickName, // 可选
        viewertoken: "17852026153", // 如果直播间设置为密码验证，必选
        pcH5Live: true,
        fastMode: true, // 选参数，默认为true开启极速文档，为false时则使用flash文档加载
      });
      // 登录成功
      DWLive.onLoginSuccess = function (res) {
        console.log("登录成功");
      };
      // 开始直播
      DWLive.onLiveStart = function (res) { };
      // 接收公聊
      DWLive.onPublicChatMessage = function (j) {
        const msgObj = JSON.parse(j);
        msgObj.msg = that.showEm(msgObj.msg);
        that.msgList = that.msgList.concat(msgObj);
        that.scrollbottom();
      };
      // 观看时间
      DWLive.onLiveTime = function (res) { };
    },
    // 发送聊天
    async submit() {
      if (this.my_msg == "") {
        this.$message.error("请输入内容!");
        return;
      }
      await DWLive.sendPublicChatMsg(this.my_msg); // 发送公聊
      this.my_msg = "";
    },
    showEm(str) {
      if (!$.trim(str)) {
        return "";
      }
      str = str.replace(/\</g, "&lt;");
      str = str.replace(/\>/g, "&gt;");
      str = str.replace(/\n/g, "<br/>");
      str = str.replace(
        /\[em_([0-9]*)\]/g,
        '<img src="//view.csslcloud.net/img/em/$1.gif" border="0" />'
      );
      str = str.replace(
        /\[em2_([0-9]*)\]/g,
        '<img src="//view.csslcloud.net/img/em2/$1.png" border="0" />'
      );
      var nmsg = ""; // 解析表情
      $.each(str.split(" "), function (i, n) {
        n = $.trim(n);
        if (
          n.indexOf("[uri_") == 0 &&
          n.indexOf("]") == n.length - 1 &&
          n.length > 6
        ) {
          var u = n.substring(5, n.length - 1) + " ";
          nmsg +=
            '<a target="_blank" style="color: #2f53ff" href="' +
            u +
            '">' +
            u +
            "</a>" +
            " ";
        } else {
          nmsg += n + " ";
        }
      });
      // 解析图片
      var reg = new RegExp(/\[img_http(s)?:\/\/(.*?)\]/g);
      var isImage = reg.test(str);
      if (isImage) {
        var sIndex = str.indexOf("_") + 1;
        nmsg = str.slice(sIndex, str.length - 1);
        var imgTag =
          '<div class="chatImage" style="width: 100%" ><img src="' +
          nmsg +
          '"  style="width: 100%;cursor:pointer;" onclick="showMsgImage(event)"/></div>';
        return imgTag;
      }
      return nmsg;
    },
    async loadgetLbClassCourse() {
      /*    let userId = await localStorage.getItem("userId");
      getLbClassCourse(1, userId, undefined, undefined, undefined, undefined, this.id).then((res) => {});
   */
    },
    getMarquee() {
      DWLive.getMarquee = (data) => {
        if (!!data) {
          this.marquee = data;
          return;
        }
        this.marquee = $("#viewerMarquee").text();
      };
      window.on_cc_swf_loading_completed = () => {
        // 需要播放器加载完毕再执行
        setTimeout(() => {
          var marqueeInfo = $.trim(this.marquee);
          DWLive.showMarquee(marqueeInfo);
          DWLive.showMarqueeDoc(marqueeInfo);

        }, 1000);
      };
    },

    scrollbottom() {
      this.$nextTick(() => {
        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
      });
    },
    /* --------学习记录--------------- */
    /* 添加记录 */
    async getLbClassCourse() {
      if (!this.IsPurchase || !this.userInfo) {
        return;
      }
      const that = this;
    },
  },
};
</script>
<style lang="less" scoped>
// 视频上方提示
.room-Top {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  z-index: 99;

  // 左侧提示
  .wrapTopsCenTopL {
    width: auto;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0px 10px 0px 10px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f15817;
    }
  }

  // 右侧关闭
  .wrapTopsCenTopR {
    width: auto;
    height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
    }

    img {
      width: 11px;
      height: 11px;
      margin: 0px 10px 0px 5px;
    }
  }
}

/* 讲师 */
.detail-ter {
  width: 100%;
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 0px 20px;

  .ter {
    color: #fff;
  }
}

/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .time {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    line-height: 14px;
    flex-shrink: 0;

    .iconfont {
      font-size: 14px;
      margin-right: 8px;
    }
  }
}

// 中间视频
.videoRoom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 32px 0px;
  background: #222222;

  // 左视频
  .room-left {
    width: 880px;
    height: 100%;
    background: #222222;
    margin-right: 20px;
    position: relative;

    #drawPanel {
      width: 100% !important;
      height: 495px !important;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .prism-player {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  // 右头像/聊天
  .room-right {
    height: 100%;
    flex-shrink: 0;

    #livePlayer {
      width: 320px;
      height: 240px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      margin-bottom: 20px;
    }

    img {
      width: 135px;
      height: 113px;
      margin-top: 26px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6a6a6a;
    }

    .room {
      width: 320px;
      height: 371px;
      background: #383838;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  // 聊天框
  .wrapTopsCenCenRC {
    width: 100%;
    height: 346px;

    // 聊天框顶部
    .wrapTopsCenCenRCa {
      width: 100%;
      height: 40px;
      background: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
    }

    // 聊天框内容
    .wrapTopsCenCenRCb {
      width: 100%;
      height: 260px;
      overflow: auto;

      // overflow-y:scroll;
      // overflow-x:hidden
      // 内容盒子
      .wrapTopsCenCenRCbs {
        width: 85%;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        // 左侧头像
        .wrapTopsCenCenRCbsL {
          width: 32px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        // 右侧昵称/留言
        .wrapTopsCenCenRCbsR {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b0b0b0;
          }

          p {
            width: 100%;
            height: auto;
            overflow: hidden;
            font-size: 12px;
            margin-top: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }

    // 聊天框底部
    .wrapTopsCenCenRCc {
      width: 100%;
      height: 46px;
      background: #5d5d5d;
      display: flex;
      justify-content: center;
      align-items: center;

      // 内容盒子
      .wrapTopsCenCenRCcs {
        width: 85%;
        height: 30px;
        background: #7a7a7a;
        border-radius: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // 输入框
        input {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          background: #7a7a7a;
          color: #ffffff;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
        }

        // 发送
        div {
          width: 24%;
          height: 100%;
          background: #a1a1a1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0px 15px 15px 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
          cursor: pointer;
        }
      }
    }

    //隐藏滚动条
    ::-webkit-scrollbar {
      display: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
  }
}</style>
